import { ListTypeEnum, PlayerTypeEnum } from 'src/graphql-types/globalRankingTypes'

export const getIsFormatCombined = ({ listType, playerType, matchFormat }) => {
  const combinedConditions = [
    listType === ListTypeEnum.STANDING && playerType === PlayerTypeEnum.JUNIOR,
    listType === ListTypeEnum.YEAR_END && !matchFormat,
    listType === ListTypeEnum.BONUS_POINTS,
    listType === ListTypeEnum.QUOTA
  ]

  return combinedConditions.some(Boolean)
}