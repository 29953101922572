import React, { useCallback, useState } from 'react'
import * as styles from './completed-runs-table.module.less'
import { CustomGrid } from '../../custom-grid/custom-grid'
import { Body } from '../../typography/typography'
import { ListTypeEnum, MatchFormatEnum } from 'src/graphql-types/globalRankingTypes'
import { useTranslation } from 'react-i18next'
import Dropdown from '../../dropdown/dropdown'
import Button from '../../button/button'
import { useOrgLevel } from 'src/utils/auth'
import CustomDialog from '../../custom-dialog/custom-dialog'
import { TD_GET_RANK_LISTS_METADATA } from '../queries'
import { transformSortDirection, useControlledQuery } from '../../table-controls/table-controls'
import { getFiltersFromQueryParams } from '../utils/getFiltersFromQueryParams'
import { getIsFormatCombined } from '../utils/getIsFormatCombined'
import { getFamilyAgeRestriction } from '../utils/getFamilyAgeRestriction'
import { meshGatewayClient } from 'src/apollo/client'
import { TD_GetRankLists, TD_GetRankLists_td_rankLists_items } from 'src/graphql-types/TD_GetRankLists'
import AdminTable, { DataCols } from '../../admin-table/admin-table'
import Icon from '../../icon/icon'
import moment from 'moment'
import StatusLabel, { LabelVariety } from '../../status-label/status-label'
import { navigate } from 'gatsby'
import { getGenerateRankListInput } from '../utils/getGenerateRankListsInput'
import NewManualRankingRunDialog from '../ranking-run/new-manual-ranking-run-dialog'
import { RankingRunProps } from './ranking-runs'
import { TD_RankListRunGroups_td_rankListRunGroups_items } from 'src/graphql-types/TD_RankListRunGroups'
import Spinner from 'src/components/spinner/spinner'

interface CompletedRunsTableProps {
  rankingRun: RankingRunProps;
  handleCreateRun: (data: any) => void;
  loading: boolean;
  error: any;
}

const CompletedRunsTable: React.FC<CompletedRunsTableProps> = ({
  rankingRun,
  handleCreateRun,
  loading,
  error,
}) => {
  const { t } = useTranslation()
  const { isNational } = useOrgLevel()
  const [filters, setFilters] = useState({ publishStatus: '' })
  const [newRunDialog, setNewRunDialog] = useState(false)

  const transformVariables = (optionsWithControls: any) => {
    const { limit = 10, offset = 0, sorts = [] } = optionsWithControls ?? {}
    const [sortsObject = {}] = sorts
    const { sortDirection = '', property = '' } = sortsObject

    const sort = () => {
      if (sortDirection === '' || property === '') {
        return { sort: { field: 'updatedAt', direction: 'DESC' } }
      } else {
        return {
          sort: {
            field: property,
            direction: transformSortDirection(sortDirection)
          }
        }
      }
    }

    const isVisible = filters.publishStatus === 'PUBLISHED'
    const visibilityFilter = filters.publishStatus ? { visible: isVisible } : {}

    const ranklistFilters = getFiltersFromQueryParams({ query: rankingRun })
    const genderModifier =
      ranklistFilters.genderModifier === 'null' ? null : ranklistFilters.genderModifier

    const isCombined = getIsFormatCombined({
      listType: rankingRun.listType,
      matchFormat: rankingRun.matchFormat,
      playerType: rankingRun.playerType
    })
    const matchFormat = isCombined ? { matchFormat: MatchFormatEnum.COMBINED } : {}

    return {
      ...sort(),
      filter: {
        ...ranklistFilters,
        ...visibilityFilter,
        ...matchFormat,
        genderModifier,
        ...getFamilyAgeRestriction(rankingRun.playerType, rankingRun.ageRestriction)
      },
      pageArgs: {
        limit,
        skip: offset
      }
    }
  }

  const getTotalItems = useCallback((data: TD_GetRankLists) => 
    data?.td_rankLists?.totalItems ?? 0, 
  [])

  const {
    data: rankListsData,
    loading: loadingRankLists,
    controlProps,
    refetch: refetchLists
  } = useControlledQuery<TD_GetRankLists>(TD_GET_RANK_LISTS_METADATA, {
    getTotalItems,
    transformVariables,
    client: meshGatewayClient,
    notifyOnNetworkStatusChange: true, // Solves refetch not triggering update
    fetchPolicy: 'network-only' // Solves refetch not triggering update
  })

  const publishFilterOptions = [
    { value: '', label: t('any published status') },
    {
      value: 'PUBLISHED',
      label: t('published')
    },
    {
      value: 'HIDDEN',
      label: t('hidden')
    }
  ]

  const columns: DataCols<TD_GetRankLists_td_rankLists_items> = [
    {
      key: 'scheduled',
      title: ' ',
      getValue: m => {
        if (!m.scheduleId) return null
        return <Icon name="sm-clock-grey" spacing={{ margins: { sm: 'left' } }} />
      },
      noPadding: true,
      sort: false,
      hidden: true,
    },
    {
      key: 'createdAt',
      title: t('run'),
      getValue: m => (
        <button className={styles.runButton}>
          {t('payment date', {
            date: moment(m?.createdAt).local()
          })}
        </button>
      )
    },
    {
      key: 'dateRange.start',
      title: t('ranking period'),
      getValue: m => {
        const { start, end } = m?.dateRange ?? {}
        return getRankingPeriod({ start, end, t })
      },
      hidden: rankingRun?.listType === ListTypeEnum.L2_QUALIFIER
    },
    {
      key: 'itemCount',
      title: t('no of players'),
      getValue: m => m.itemCount
    },
    {
      key: 'visible',
      title: t('publish status'),
      getValue: m => {
        const status = m.visible
          ? { text: t('published'), color: 'success' }
          : { text: t('hidden'), color: 'warning' }

        return <StatusLabel variety={status.color as LabelVariety}>{status.text}</StatusLabel>
      }
    },
    {
      key: 'updatedAt',
      title: t('last published'),
      getValue: m => {
        if (!m?.visible) return '-'
        return t('payment date', {
          date: moment(m?.updatedAt).local()
        })
      }
    }
  ]

  const getRankingPeriod = ({ start, end, t }) => {
    const isSameYear = 
      start && 
      new Date(start).getFullYear() === 
      end && 
      new Date(end).getFullYear()
    const translationString = isSameYear ? 'ranklist date range' : 'ranklist date range diff year'
  
    return t(translationString, {
      start: moment.utc(start?.split('T')[0]),
      end: moment.utc(end?.split('T')[0])
    })
  }

  const handleRowClick = useCallback((list) => {
    navigate(
      `/rankings/${list.id}?latestListMadeVisible=${rankingRun.latestListMadeVisible}&rankListName=${rankingRun.rankListName}`
    )
  },[rankingRun.latestListMadeVisible, rankingRun.rankListName])

  const handleCreateIndividualRun = useCallback((data) => {
    const input = getGenerateRankListInput(data, rankingRun as TD_RankListRunGroups_td_rankListRunGroups_items)
    handleCreateRun(input)
    refetchLists()
    setNewRunDialog(false)
  },[handleCreateRun, rankingRun, refetchLists])

  const handleNewRunDialogOpen = useCallback((open) => {
    setNewRunDialog(open)
  }, [])

  return (
    <>
      <CustomGrid container>
        <Body size="xl" bold spacing={{ margins: { sm: 'bottom' } }}>
          {rankingRun.listType === ListTypeEnum.L2_QUALIFIER
            ? `${t('level 2 qualifier')} ${t('lists')}`
            : t('completed runs')}
        </Body>
      </CustomGrid>
      <CustomGrid container justifyContent="space-between" spacing={{ margins: { md: 'bottom' } }}>
        <Dropdown
          options={publishFilterOptions}
          onSelect={o => setFilters({ publishStatus: o.value })}
          fluid
        />
        <Button hide={!isNational} onClick={() => handleNewRunDialogOpen(true)}>
          {t('add manual run')}
        </Button>
      </CustomGrid>
      <CustomDialog
        title={t('add new run')}
        open={newRunDialog}
        hideX
        onClose={() => handleNewRunDialogOpen(false)}
        content={
          <NewManualRankingRunDialog
            title={rankingRun.rankListName}
            onSubmit={handleCreateIndividualRun}
            onCancel={() => handleNewRunDialogOpen(false)}
            loading={loading}
            error={error}
          />
        }
      />
      {loadingRankLists && <Spinner />}
      {!!rankListsData?.td_rankLists?.items?.length &&
        <AdminTable
          data={rankListsData.td_rankLists.items || []}
          loading={loadingRankLists}
          columns={columns}
          controls={controlProps}
          onRowClick={handleRowClick}
          filters={filters}
          hideTopPaginationInfo
        />
      }
    </>
  )
}

export default CompletedRunsTable