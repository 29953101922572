import React, { useState, SetStateAction, Dispatch, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Grid } from '@material-ui/core'
import { Body, BodyRegularBold } from '../typography/typography'
import { RangeContainer, DateField } from '../formik-fields/formik-fields'
import { CustomGrid } from '../custom-grid/custom-grid'
import Button from '../button/button'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import {
  GENERATE_RANK_LIST,
  RANK_LIST_JOB,
  GET_RANKLIST_RUN_GROUPS
} from '../usta-rankings/usta-ranking-queries'
import { meshGatewayClient } from 'src/apollo/client'
import FormErrorMessage from '../form-error-message/form-error-message'
import { useQueryParams, StringParam } from 'use-query-params'
import { getRanklistFilters, defaultSorting } from '../usta-rankings/helpers'
import { retrieveRowsPerPage, retrieveRankingFilters } from 'src/utils/storage/local-storage'
import { getFiltersFromQueryParams } from '../usta-ranklist/usta-ranklist.utils'
import {
  getRunDate,
  getDateRange,
  displayDateRange
} from '../usta-ranking-dialog/usta-rankings-dialog.utils'
import { ListTypeEnum } from 'src/graphql-types/globalRankingTypes'

const initialValues = {
  startDate: new Date(),
  endDate: new Date(),
  runDate: new Date()
}

interface NewRunDialogProps {
  title: string
  setDialog: Dispatch<SetStateAction<boolean>>
  refetch: any
}

export const NewRunDialog: React.FC<NewRunDialogProps> = ({ title, setDialog, refetch }) => {
  const { t } = useTranslation()
  const [polling, setPolling] = useState(false)
  const storedRowsPerPage = retrieveRowsPerPage()
  const storedRankingFilters = retrieveRankingFilters()

  const [query] = useQueryParams({
    listType: StringParam,
    playerType: StringParam,
    ageRestriction: StringParam,
    gender: StringParam,
    genderModifier: StringParam,
    matchFormat: StringParam,
    matchFormatType: StringParam,
    playerLevel: StringParam,
    familyCategory: StringParam,
    region: StringParam
  })

  const [dates, setDates] = useState<Record<'startDate' | 'endDate' | 'runDate', Date>>({
    startDate: new Date(),
    endDate: new Date(),
    runDate: new Date()
  })

  const [generateRankList, { loading: generatingRankList, error }] = useMutation(
    GENERATE_RANK_LIST,
    {
      client: meshGatewayClient
    }
  )
  const [checkRankListStatus, { data, stopPolling, error: rankListError }] = useLazyQuery(
    RANK_LIST_JOB,
    {
      pollInterval: 3000,
      client: meshGatewayClient
    }
  )

  // Handle ranklist job error
  useEffect(() => {
    const hasErrors = data?.rankListJob?.jobErrors?.length > 0

    if (hasErrors || rankListError) {
      stopPolling()
      setPolling(false)
    }
  }, [data, rankListError])

  // Handle successful ranklist job
  useEffect(() => {
    const hasErrors = data?.rankListJob?.jobErrors?.length > 0
    if (data?.rankListJob?.jobComplete && !hasErrors) {
      stopPolling()
      setPolling(false)
      setDialog(false)
      refetch?.()
    }
  }, [data])

  const refetchRanklistRunGroupQuery = {
    query: GET_RANKLIST_RUN_GROUPS,
    variables: {
      ranklistRunGroupFilter: getRanklistFilters(storedRankingFilters),
      pageArgs: { limit: storedRowsPerPage, skip: 0 },
      sort: defaultSorting
    }
  }

  const handleSubmit = async (values: any) => {
    const { startDate, endDate, runDate } = values
    const filters = getFiltersFromQueryParams({ query, overrideGenderModifier: true })

    const response = await generateRankList({
      variables: {
        input: {
          ...filters,
          ...getDateRange({ listType: filters.listType as ListTypeEnum, startDate, endDate }),
          ...getRunDate({ listType: filters.listType as ListTypeEnum, runDate })
        }
      },
      refetchQueries: [refetchRanklistRunGroupQuery]
    })

    checkRankListStatus({ variables: { id: response?.data?.generateRankList?.id } })
    setPolling(true)
  }

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values }) => (
          <Form>
            <CustomGrid container direction="column" spacing={{ margins: { lg: 'bottom' } }}>
              <h3>{title}</h3>
              <Grid container spacing={4}>
                {displayDateRange(query.listType as ListTypeEnum) && (
                  <>
                    <Grid item xs>
                      <Body size="md" spacing={{ margins: { md: 'top', xs: 'bottom' } }}>
                        {t('run period start')}
                      </Body>
                      <RangeContainer>
                        <DateField
                          name="startDate"
                          onChange={date => setDates({ ...dates, startDate: date })}
                          datePickerProps={{
                            disabled: false,
                            selected: new Date(values.startDate),
                            popperPlacement: 'bottom-end',
                            maxDate: new Date()
                          }}
                        />
                      </RangeContainer>
                    </Grid>
                    <Grid item xs>
                      <Body size="md" spacing={{ margins: { md: 'top', xs: 'bottom' } }}>
                        {t('run period end')}
                      </Body>
                      <RangeContainer>
                        <DateField
                          name="endDate"
                          onChange={date => setDates({ ...dates, endDate: date })}
                          datePickerProps={{
                            disabled: false,
                            selected: new Date(values.endDate),
                            popperPlacement: 'bottom',
                            minDate: new Date(values.startDate)
                          }}
                        />
                      </RangeContainer>
                    </Grid>
                  </>
                )}
                {!displayDateRange(query.listType as ListTypeEnum) && (
                  <Grid item xs>
                    <BodyRegularBold spacing={{ margins: { xs: 'top', xxs: 'bottom' } }}>
                      {t('run date')}
                    </BodyRegularBold>
                    <RangeContainer noMargin>
                      <DateField
                        name="runDate"
                        onChange={date => setDates({ ...dates, runDate: date })}
                        datePickerProps={{
                          disabled: false,
                          selected: new Date(values.runDate),
                          popperPlacement: 'bottom',
                          maxDate: new Date()
                        }}
                      />
                    </RangeContainer>
                  </Grid>
                )}
              </Grid>
            </CustomGrid>
            <CustomGrid
              container
              justify="flex-end"
              spacing={{ margins: { lg: 'top' } }}
              style={{ marginTop: 150 }}
            >
              <Button onClick={() => setDialog(false)} type="button" level="tertiary">
                {t('cancel')}
              </Button>
              <Button
                type="submit"
                spacing={{ margins: { sm: 'left' } }}
                loading={polling || generatingRankList}
              >
                {query.listType === ListTypeEnum.L2_QUALIFIER ? t('save list') : t('save run')}
              </Button>
            </CustomGrid>
          </Form>
        )}
      </Formik>
      <CustomGrid container justify="flex-end">
        {error && (
          <FormErrorMessage
            spacing={{ margins: { md: 'top' } }}
            message={t('generate ranklist error')}
          />
        )}
        {!error && data?.rankListJob?.jobErrors && (
          <FormErrorMessage
            spacing={{ margins: { md: 'top' } }}
            message={data?.rankListJob?.jobErrors?.[0]}
          />
        )}
      </CustomGrid>
    </>
  )
}
