import { Grid } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/button/button'
import { CustomGrid } from 'src/components/custom-grid/custom-grid'
import Dropdown from 'src/components/dropdown/dropdown'
import { Body } from 'src/components/typography/typography'
import { TD_RankListSchedules_td_rankListSchedules } from 'src/graphql-types/TD_RankListSchedules'
import { RankListSchedulePeriodEnum } from 'src/graphql-types/globalRankingTypes'

export enum FrequencyOption {
  WEEKLY = 'weekly',
  MONTHLY_LAST_DAY = 'monthly_last_day',
  MONTHLY_SPECIFIC_DAY = 'monthly_specific_day'
}

export enum DayOfTheWeekEnum {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday'
}

export const scheduledRankingPeriodOptions = [
  { value: RankListSchedulePeriodEnum.ONE_MONTH, label: '1 month previous' },
  { value: RankListSchedulePeriodEnum.ONE_YEAR, label: '1 year previous' }
]

export const frequencyOptions = [
  { value: FrequencyOption.WEEKLY, label: 'Weekly' },
  { value: FrequencyOption.MONTHLY_LAST_DAY, label: 'Monthly - Last day' },
  { value: FrequencyOption.MONTHLY_SPECIFIC_DAY, label: 'Monthly - Specific day' }
]

export const dayOfTheWeekOptions = [
  { value: DayOfTheWeekEnum.MONDAY, label: 'Monday' },
  { value: DayOfTheWeekEnum.TUESDAY, label: 'Tuesday' },
  { value: DayOfTheWeekEnum.WEDNESDAY, label: 'Wednesday' },
  { value: DayOfTheWeekEnum.THURSDAY, label: 'Thursday' },
  { value: DayOfTheWeekEnum.FRIDAY, label: 'Friday' },
  { value: DayOfTheWeekEnum.SATURDAY, label: 'Saturday' },
  { value: DayOfTheWeekEnum.SUNDAY, label: 'Sunday' }
]

export const dayOfTheMonthOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' }
]

export const timeOptions = [
  { value: '0,0', label: '12:00 AM' },
  { value: '0,30', label: '12:30 AM' },
  { value: '1,0', label: '1:00 AM' },
  { value: '1,30', label: '1:30 AM' },
  { value: '2,0', label: '2:00 AM' },
  { value: '2,30', label: '2:30 AM' },
  { value: '3,0', label: '3:00 AM' },
  { value: '3,30', label: '3:30 AM' },
  { value: '4,0', label: '4:00 AM' },
  { value: '4,30', label: '4:30 AM' },
  { value: '5,0', label: '5:00 AM' },
  { value: '5,30', label: '5:30 AM' },
  { value: '6,0', label: '6:00 AM' },
  { value: '6,30', label: '6:30 AM' },
  { value: '7,0', label: '7:00 AM' },
  { value: '7,30', label: '7:30 AM' },
  { value: '8,0', label: '8:00 AM' },
  { value: '8,30', label: '8:30 AM' },
  { value: '9,0', label: '9:00 AM' },
  { value: '9,30', label: '9:30 AM' },
  { value: '10,0', label: '10:00 AM' },
  { value: '10,30', label: '10:30 AM' },
  { value: '11,0', label: '11:00 AM' },
  { value: '11,30', label: '11:30 AM' },
  { value: '12,0', label: '12:00 PM' },
  { value: '12,30', label: '12:30 PM' },
  { value: '13,0', label: '1:00 PM' },
  { value: '13,30', label: '1:30 PM' },
  { value: '14,0', label: '2:00 PM' },
  { value: '14,30', label: '2:30 PM' },
  { value: '15,0', label: '3:00 PM' },
  { value: '15,30', label: '3:30 PM' },
  { value: '16,0', label: '4:00 PM' },
  { value: '16,30', label: '4:30 PM' },
  { value: '17,0', label: '5:00 PM' },
  { value: '17,30', label: '5:30 PM' },
  { value: '18,0', label: '6:00 PM' },
  { value: '18,30', label: '6:30 PM' },
  { value: '19,0', label: '7:00 PM' },
  { value: '19,30', label: '7:30 PM' },
  { value: '20,0', label: '8:00 PM' },
  { value: '20,30', label: '8:30 PM' },
  { value: '21,0', label: '9:00 PM' },
  { value: '21,30', label: '9:30 PM' },
  { value: '22,0', label: '10:00 PM' },
  { value: '22,30', label: '10:30 PM' },
  { value: '23,0', label: '11:00 PM' },
  { value: '23,30', label: '11:30 PM' }
]

interface EditScheduledRunsDialogProps {
  onCancel: () => void
  onSubmit: (data: any, existingScheduleId?: string | null) => void
  loading: boolean
  existingActiveSchedule?: TD_RankListSchedules_td_rankListSchedules | null
}

const EditScheduledRunsDialog: React.FC<EditScheduledRunsDialogProps> = ({
  onCancel,
  onSubmit,
  loading,
  existingActiveSchedule,
}) => {
  const { t } = useTranslation()
  const { control, handleSubmit, setValue } = useForm()
  const [frequency, setFrequency] = useState<typeof frequencyOptions[0]>(frequencyOptions[0])
  const [dayOfTheWeek, setDayOfTheWeek] = useState<typeof dayOfTheWeekOptions[0]>(dayOfTheWeekOptions[0])
  const [dayOfTheMonth, setDayOfTheMonth] = useState<typeof dayOfTheMonthOptions[0]>(dayOfTheMonthOptions[0])
  const [time, setTime] = useState<typeof timeOptions[0]>(timeOptions[0])

  // set default form values on first render
  useEffect(() => {
    setValue('frequency', frequency)
    setValue('dayOfTheWeek', dayOfTheWeekOptions[0])
    setValue('time', timeOptions[0])
    setValue('rankingPeriod', scheduledRankingPeriodOptions[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue])

  // update default form values when frequency changes
  useEffect(() => {
    if (frequency.value === FrequencyOption.WEEKLY) {
      setValue('dayOfTheWeek', dayOfTheWeek)
      setValue('dayOfTheMonth', null)
    }
    if (frequency.value === FrequencyOption.MONTHLY_LAST_DAY) {
      setValue('dayOfTheWeek', null)
      setValue('dayOfTheMonth', null)
    }
    if (frequency.value === FrequencyOption.MONTHLY_SPECIFIC_DAY) {
      setValue('dayOfTheWeek', null)
      setValue('dayOfTheMonth', dayOfTheMonth)
    }
    setValue('time', time)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequency, setValue])

  const handleOnSubmit = useCallback(
    (data) => {
      // If the dialog is opened to edit an existing schedule, pass the schedule id to the onSubmit handler
      // so the parent component can call the update mutation instead of creating a new schedule.
      onSubmit(data, existingActiveSchedule?.id)
    },
    [existingActiveSchedule, onSubmit]
  )

  const handleOnCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  const handleFieldChange = useCallback((field, value) => {
    if (field.name === 'frequency') {
      setFrequency(value)
      setValue('frequency', value)
    }
    if (field.name === 'dayOfTheWeek') {
      setDayOfTheWeek(value)
      setValue('dayOfTheWeek', value)
    }
    if (field.name === 'dayOfTheMonth') {
      setDayOfTheMonth(value)
      setValue('dayOfTheMonth', value)
    }
    if (field.name === 'time') {
      setTime(value)
      setValue('time', value)
    }
    return field.onChange(value)
  }, [setValue])

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} data-testid='batch-ranking-run-form'>
      <CustomGrid container direction="column">
        <Body size="md">{t('scheduled runs description')}</Body>
        <Body size="lg" bold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
          {t('frequency')}
        </Body>
        <Controller
          name="frequency"
          control={control}
          render={({ field }) => (
            <Dropdown
              onSelect={(value) => handleFieldChange(field, value)}
              options={frequencyOptions}
              fluid
            />
          )}
        />
        <Grid container justifyContent="space-between">
          <CustomGrid
            item
            xs
            spacing={{ margins: { xs: 'right' } }}
            hide={frequency?.value !== FrequencyOption.WEEKLY}
          >
            <Body size="lg" bold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
              {t('day of the week')}
            </Body>
            <Controller
              name="dayOfTheWeek"
              control={control}
              render={({ field }) => (
                <Dropdown
                  onSelect={(value) => handleFieldChange(field, value)}
                  options={dayOfTheWeekOptions}
                  fluid
                />
              )}
            />
          </CustomGrid>
          <CustomGrid
            item
            xs
            spacing={{ margins: { xs: 'right' } }}
            hide={frequency?.value !== FrequencyOption.MONTHLY_SPECIFIC_DAY}
          >
            <Body size="lg" bold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
              {t('day of the month')}
            </Body>
            <Controller
              name="dayOfTheMonth"
              control={control}
              render={({ field }) => (
                <Dropdown
                  onSelect={(value) => handleFieldChange(field, value)}
                  options={dayOfTheMonthOptions}
                  fluid
                />
              )}
            />
          </CustomGrid>
          <CustomGrid
            item
            xs={frequency?.value === FrequencyOption.MONTHLY_LAST_DAY ? 6 : true}
            spacing={
              frequency?.value === FrequencyOption.MONTHLY_LAST_DAY
                ? undefined
                : { margins: { xs: 'left' } }
            }
          >
            <Body size="lg" bold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
              {t('time')}
            </Body>
            <Controller
              name="time"
              control={control}
              render={({ field }) => (
                <Dropdown
                  onSelect={(value) => handleFieldChange(field, value)}
                  options={timeOptions}
                  fluid
                />
              )}
            />
          </CustomGrid>
        </Grid>
        <CustomGrid item xs={6}>
          <Body size="lg" bold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
            {t('scheduled ranking period')}
          </Body>
          <Controller
            name="rankingPeriod"
            control={control}
            render={({ field }) => (
              <Dropdown
                onSelect={(value) => handleFieldChange(field, value)}
                options={scheduledRankingPeriodOptions}
                fluid
              />
            )}
          />
        </CustomGrid>
        <Body size="sm" color={Body.color.SEMI_DARK_GREY} spacing={{ margins: { xxs: 'top' } }}>
          {t('back draw ranking data')}
        </Body>
        <CustomGrid container justifyContent="flex-end" spacing={{ margins: { lg: 'top' } }}>
          <Button
            onClick={handleOnCancel}
            type="button"
            level="tertiary"
            disabled={
              loading
            }
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            spacing={{ margins: { sm: 'left' } }}
            loading={
              loading
            }
          >
            {existingActiveSchedule ? t('update schedule') : t('set schedule')}
          </Button>
        </CustomGrid>
      </CustomGrid>
    </form>
  )
}

export default EditScheduledRunsDialog