import React from 'react'
import SEO from 'src/components/seo'
import Layout from 'src/components/layout/layout'
import { usePrivateRoute } from 'src/utils/auth'
import RankingRuns from 'src/components/rankings/ranking-runs/ranking-runs'
import USTARanklist from 'src/components/usta-ranklist/usta-ranklist'
import { useV2Rankings } from 'src/utils/useV2Rankings'

const IndexPage: React.FC = () => {
  usePrivateRoute()
  return (
    <Layout>
      <SEO title="Rankings group" />
      { useV2Rankings() ? <RankingRuns /> : <USTARanklist />}
    </Layout>
  )
}

export default IndexPage
