export const getFiltersFromQueryParams = ({
  query,
  overrideGenderModifier
}: { query: any, overrideGenderModifier?: string }) => {
  const ACCEPTABLE_INPUT_KEYS = [
    'listType',
    'playerType',
    'familyCategory',
    'playerLevel',
    'gender',
    'genderModifier',
    'ageRestriction',
    'matchFormat',
    'matchFormatType',
    'divisionType',
    'region'
  ]

  const validQueryParams = Object.entries(query).filter(([key, value]) => {
    // Always use genderModifier query
    if (key === 'genderModifier' && !overrideGenderModifier && value !== 'null') return true

    return ACCEPTABLE_INPUT_KEYS.includes(key) && value && value !== 'null'
  })
  return Object.fromEntries(validQueryParams)
}