import React from 'react'
import { DataCols } from '../admin-table/admin-table'
import Icon from '../icon/icon'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { RankListSchedules_rankListSchedules } from 'src/graphql-types/RankListSchedules'
import { parseCronValue } from 'src/utils/parse-cron-value/parse-cron-value'
import Button from '../button/button'
import { useOrgLevel } from 'src/utils/auth'
import { getNextRankingPeriod, getSchedulePeriodFromEnum } from './usta-ranklist.utils'

interface ColumnsProps {
  handleEditSchedule: (id: string) => void
}

export function useColumns({ handleEditSchedule }: ColumnsProps) {
  const { t } = useTranslation()
  const { isNational } = useOrgLevel()

  const columns: DataCols<RankListSchedules_rankListSchedules & { id: string }> = [
    {
      key: 'scheduled',
      title: ' ',
      getValue: () => <Icon name="sm-clock" spacing={{ margins: { sm: 'left' } }} />,
      noPadding: true,
      sort: false
    },
    {
      key: 'nextRun',
      title: t('next run'),
      getValue: m => {
        return t('ranking schedule date', {
          date: moment(m.nextRunAt).local()
        })
      }
    },
    {
      key: 'nextRankingPeriod',
      title: t('next ranking period'),
      getValue: m =>
        getNextRankingPeriod({
          start: m.data?.input?.dateRange?.start,
          end: m.data?.input?.dateRange?.end,
          nextRunAt: m.nextRunAt,
          t
        })
    },
    {
      key: 'runFrequency',
      title: t('run frequency'),
      getValue: m => parseCronValue({ value: m.data?.frequency, t, nextRunAt: m.nextRunAt })
    },
    {
      key: 'scheduledRankingPeriod',
      title: t('scheduled ranking period'),
      getValue: m => getSchedulePeriodFromEnum({ period: m.data?.period, t })
    },
    {
      key: 'edit schedule',
      title: ' ',
      getValue: m => (
        <Button
          hide={!isNational}
          level="tertiary"
          size="sm"
          onClick={() => handleEditSchedule(m.id)}
        >
          {t('edit schedule')}
        </Button>
      )
    }
  ]

  return columns
}
