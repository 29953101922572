import React from 'react'
import { CustomGrid } from '../../custom-grid/custom-grid'
import { Body } from '../../typography/typography'
import AdminTable from '../../admin-table/admin-table'
import { useTranslation } from 'react-i18next'
import useRankListScheduledColumns from '../utils/useRankListScheduleColumns'
import { TD_RankListSchedules_td_rankListSchedules } from 'src/graphql-types/TD_RankListSchedules'
import { ScheduleDetailsType } from '../ranking-runs/ranking-runs'

interface ScheduledRunTableProps {
  loadingSchedules: boolean
  activeSchedule?: TD_RankListSchedules_td_rankListSchedules | null
  displayScheduledRuns: boolean
  handleEditSchedule: (schedule: ScheduleDetailsType) => void
}

const ScheduledRunTable: React.FC<ScheduledRunTableProps> = ({
  loadingSchedules,
  activeSchedule,
  displayScheduledRuns,
  handleEditSchedule,
}) => {
  const { t } = useTranslation()

  const scheduledRunsCols = useRankListScheduledColumns({ handleEditSchedule })

  return (
    <>
      {displayScheduledRuns && activeSchedule && (
        <CustomGrid container hide={!activeSchedule} spacing={{ margins: { lg: 'bottom' } }}>
          <Body size="xl" bold spacing={{ margins: { sm: 'bottom' } }}>
            {t('scheduled runs')}
          </Body>
          <AdminTable
            data={activeSchedule ? [activeSchedule] : ([] as any)}
            loading={loadingSchedules}
            columns={scheduledRunsCols}
            disableRowsPerPage
            hideTopPaginationInfo
            rowProps={{ scheduleRowStyle: true }}
            spacing={{ margins: { md: 'bottom' } }}
          />
        </CustomGrid>
      )}
    </>
  )
}

export default ScheduledRunTable