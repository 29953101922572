import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as styles from './ranking-runs.module.less'
import { useTranslation } from 'react-i18next'
import { PageMaxWidth } from '../../util-components/util-components'
import Button from '../../button/button'
import { navigate } from 'gatsby'
import Icon from '../../icon/icon'
import RankingRunsHeader from './ranking-runs-header'
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params'
import Panel from '../../panel/panel'
import ScheduledRunTable from '../ranking-run/scheduled-run-table'
import CompletedRunsTable from './completed-runs-table'
import { ListTypeEnum } from 'src/graphql-types/globalRankingTypes'
import { useRankListSchedule } from '../utils/useRankListSchedule'
import { generateRanklistName } from 'src/utils/generate-ranklist-name/generate-ranklist-name'
import { toast } from 'react-toastify'
import { TD_CREATE_RANK_LIST_SCHEDULE, TD_GENERATE_RANK_LIST, TD_STOP_SCHEDULED_RANK_LIST_JOB, TD_UPDATE_RANK_LIST_SCHEDULE } from '../queries'
import { meshGatewayClient } from 'src/apollo/client'
import { useMutation } from '@apollo/client'
import CustomDialog from 'src/components/custom-dialog/custom-dialog'
import EditScheduledRunsDialog from './edit-scheduled-runs-dialog'
import { getScheduleInput } from '../utils/getScheduleInput'
import { ScheduleCheckedEnum } from '../ranking-run/schedule-ranking-run'
import { TD_RankListSchedules_td_rankListSchedules } from 'src/graphql-types/TD_RankListSchedules'
import { td_GenerateRankListInput } from 'src/graphql-types/TennisDataRankingTypes'
import { Option } from 'src/components/dropdown/dropdown'
import { TD_CreateRankListSchedule } from 'src/graphql-types/TD_CreateRankListSchedule'
import { TD_UpdateRankListSchedule } from 'src/graphql-types/TD_UpdateRankListSchedule'
import { TD_StopScheduledRankListJob } from 'src/graphql-types/TD_StopScheduledRankListJob'

export interface RankingRunProps {
  rankListName?: string | null
  listType?: string | null
  playerType?: string | null
  ageRestriction?: string | null
  gender?: string | null
  genderModifier?: string | null
  matchFormat?: string | null
  matchFormatType?: string | null
  playerLevel?: string | null
  divisionType?: string | null
  region?: string | null
  latestListMadeVisible?: string | null
  familyCategory?: string | null
  ranklistJobs?: (string | null)[] | null
  editScheduleId?: string | null
}

export interface ScheduleDetailsType {
  scheduleId?: string
  dayOfTheMonth?: Option
  dayOfTheWeek?: Option
  frequency?: Option
  rankingPeriod?: Option
  time?: Option
}

const RankingRuns = () => {
  const { t } = useTranslation()
  const [generateRankListError, setGenerateRankListError] = useState<string | undefined>()
  const [editScheduleRunDialogOpen, setEditScheduleRunDialogOpen] = useState<boolean>(false)
  const [activeSchedule, setActiveSchedule] = useState<TD_RankListSchedules_td_rankListSchedules | null>()
  
  const [rankingRun, setRankingRun] = useQueryParams({
    listType: StringParam,
    playerType: StringParam,
    ageRestriction: StringParam,
    gender: StringParam,
    genderModifier: StringParam,
    matchFormat: StringParam,
    matchFormatType: StringParam,
    playerLevel: StringParam,
    divisionType: StringParam,
    region: StringParam,
    latestListMadeVisible: StringParam,
    familyCategory: StringParam,
    ranklistJobs: ArrayParam,
    editScheduleId: StringParam
  })

  const {
    playerType,
    listType,
    ageRestriction,
    gender,
    genderModifier,
    matchFormat,
    matchFormatType,
    playerLevel,
    divisionType,
    familyCategory,
    region
  } = rankingRun

  const rankListName = useMemo(() => generateRanklistName(
    {
      playerType,
      listType,
      ageRestriction,
      gender,
      genderModifier,
      matchFormat,
      matchFormatType,
      playerLevel,
      divisionType,
      familyCategory,
      region
    } as any,
    t
  ), [ageRestriction, divisionType, familyCategory, gender, genderModifier, listType, matchFormat, matchFormatType, playerLevel, playerType, region, t])

  const handleBackClick = useCallback(() => {
    navigate('/rankings')
  }, [])

  const { data: rankListSchedules, loading: loadingSchedules, refetch: refetchRanklistSchedules } = useRankListSchedule()

  const [createRankListSchedule, { loading: schedulingRun }] = useMutation<TD_CreateRankListSchedule>(
    TD_CREATE_RANK_LIST_SCHEDULE,
    {
      client: meshGatewayClient,
      onCompleted: () => {
        refetchRanklistSchedules()
      }
    }
  )

  const [updateRankListSchedule] = useMutation<TD_UpdateRankListSchedule>(
    TD_UPDATE_RANK_LIST_SCHEDULE,
    {
      client: meshGatewayClient,
      onCompleted: () => {
        refetchRanklistSchedules()
      }
    }
  )

  const [disableRankListSchedule] = useMutation<TD_StopScheduledRankListJob>(
    TD_STOP_SCHEDULED_RANK_LIST_JOB,
    {
      client: meshGatewayClient,
      onCompleted: () => {
        refetchRanklistSchedules()
      }
    }
  )

  useEffect(() => {
    const relevantActiveSchedule = rankListSchedules?.td_rankListSchedules?.find(s => !s?.disabled)
    setActiveSchedule(relevantActiveSchedule)
    setScheduleChecked(relevantActiveSchedule ? ScheduleCheckedEnum.ON : ScheduleCheckedEnum.OFF)
  }, [rankListSchedules])
  

  const [scheduleChecked, setScheduleChecked] = useState<ScheduleCheckedEnum>(
    activeSchedule ? ScheduleCheckedEnum.ON : ScheduleCheckedEnum.OFF
  )

  const handleEditSchedule = useCallback((scheduleDetails: ScheduleDetailsType, existingScheduleId?: string | null) => {
    // first check if we need to update or create a new schedule
    if (scheduleDetails?.scheduleId) {
      setEditScheduleRunDialogOpen(true)
    } else if (existingScheduleId) {
      // if we have an existing schedule id, we need to update the schedule rather than create a new one
      const updateScheduleInput = {
        variables: {
          input: { id: existingScheduleId, ...getScheduleInput(scheduleDetails, rankingRun) },
        }
      }
      updateRankListSchedule(updateScheduleInput)
      setEditScheduleRunDialogOpen(false)
    } else { // else we need to create a new schedule
      const newScheduleInput = {
        variables: {
          input: getScheduleInput(scheduleDetails, rankingRun)
        }
      }
      createRankListSchedule(newScheduleInput)
      setEditScheduleRunDialogOpen(false)
    }
  }, [createRankListSchedule, rankingRun, updateRankListSchedule])

  const handleCancelEditSchedule = useCallback(() => {
    setEditScheduleRunDialogOpen(false)
    setScheduleChecked(ScheduleCheckedEnum.OFF)
  },[])

  const handleSetScheduleChecked = useCallback((checked: boolean) => {
    if (!checked) {
      setScheduleChecked(ScheduleCheckedEnum.OFF)
      disableRankListSchedule({
        variables: {
          id: activeSchedule?.id
        }
      })
    } else {
      setScheduleChecked(ScheduleCheckedEnum.ON)
    }
    setEditScheduleRunDialogOpen(checked)
  }, [activeSchedule?.id, disableRankListSchedule])

  const displayScheduledRuns = useMemo(() => 
    ![ListTypeEnum.L2_QUALIFIER].includes(rankingRun.listType as ListTypeEnum), 
  [rankingRun.listType]
  )

  const [generateRankList, { loading: generatingList }] = useMutation(
    TD_GENERATE_RANK_LIST,
    {
      client: meshGatewayClient,
    }
  )

  const handleCreateIndividualRun = useCallback(async (input: td_GenerateRankListInput) => {
    const response = await generateRankList({
      variables: {
        input
      }
    })
    if (response?.data?.td_generateRankList?.__typename === 'td_GenerateRankListSuccess') {
      toast.success(t('run created'))
    }
    if (response?.data?.td_generateRankList?.__typename === 'td_GenerateRankListErrors') {
      toast.error(JSON.stringify(response?.data?.td_generateRankList?.errors))
      setGenerateRankListError(JSON.stringify(response?.data?.td_generateRankList?.errors))
    }
  },[generateRankList, t])

  return (
    <PageMaxWidth>
      <Button onClick={handleBackClick} level='tertiary' className={styles.backButton}>
        <Icon name="sm-left" className={styles.arrowIcon} />
        {t('back to rankings')}
      </Button>
      <RankingRunsHeader 
        rankingRun={{ ...rankingRun, rankListName }} 
        scheduleDisabled={false}
        setSchedule={handleSetScheduleChecked}
        scheduleChecked={scheduleChecked}
      />
      <Panel extendedPadding>
        <ScheduledRunTable
          loadingSchedules={loadingSchedules}
          activeSchedule={activeSchedule}
          displayScheduledRuns={displayScheduledRuns}
          handleEditSchedule={handleEditSchedule}
        />
        <CompletedRunsTable 
          rankingRun={{ ...rankingRun, rankListName }} 
          handleCreateRun={handleCreateIndividualRun}
          error={generateRankListError}
          loading={generatingList}
        />
      </Panel>
      <CustomDialog
        title={t('scheduled runs')}
        open={editScheduleRunDialogOpen}
        hideX
        visibleOverflow
        onClose={() => setEditScheduleRunDialogOpen(false)}
        content={
          <EditScheduledRunsDialog
            onCancel={handleCancelEditSchedule}
            onSubmit={handleEditSchedule}
            loading={schedulingRun}
            existingActiveSchedule={activeSchedule}
          />
        }
      />
    </PageMaxWidth>
  )
}

export default RankingRuns