import React, { Dispatch, SetStateAction } from 'react'
import { Grid, Switch } from '@material-ui/core'
import * as styles from './schedule-ranking-runs.module.less'
import Icon from '../icon/icon'
import { useTranslation } from 'react-i18next'
import { Body } from '../typography/typography'
import { withStyles, createStyles } from '@material-ui/core/styles'
import themeColors from 'src/styles/theme'
import classNames from 'classnames'

export const SliderComponent = withStyles(theme =>
  createStyles({
    switchBase: {
      padding: 1,
      marginLeft: -1,
      '&$checked': {
        transform: 'translateX(16px)',
        marginTop: -1,
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: themeColors['accent-color-2'],
          opacity: 1,
          border: 'none'
        }
      },
      top: -1
    },
    thumb: {
      width: 18,
      height: 18,
      border: '1px solid rgba(45,50,54, 0.4 )'
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border'])
    },
    checked: {}
  })
)(({ classes, ...props }: any) => {
  return (
    <Switch
      disableRipple
      classes={{
        root: styles.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  )
})

interface ScheduleRankingRunsProps {
  checked: boolean
  setChecked: (checked: boolean) => void
  disabled?: boolean
}

export function ScheduleRankingRuns({ checked, setChecked, disabled }: ScheduleRankingRunsProps) {
  const { t } = useTranslation()

  return (
    <Grid
      className={classNames(styles.container, {
        [styles.containerActive]: checked,
        [styles.disabled]: disabled
      })}
    >
      <Grid container alignItems="center">
        <Icon name="sm-clock" />
        <div className={styles.textAligner}>
          <Body size="md" bold spacing={{ margins: { xs: 'left' } }}>
            {t('scheduled runs')}
          </Body>
        </div>
        <SliderComponent
          checked={checked}
          onChange={() => setChecked(!checked)}
          disabled={disabled}
        />
        <span className={classNames(styles.sliderStatus, { [styles.sliderStatusActive]: checked })}>
          {checked ? t('on') : t('off')}
        </span>
      </Grid>
    </Grid>
  )
}
