import React, { useCallback, useMemo } from 'react'
import * as styles from '../ranking-run/ranking-run-header.module.less'
import Panel from '../../panel/panel'
import { Divider, Grid } from '@material-ui/core'
import { Body, H2 } from '../../typography/typography'
import { useOrgLevel } from 'src/utils/auth'
import { useTranslation } from 'react-i18next'
import { ListTypeEnum } from 'src/graphql-types/globalRankingTypes'
import ScheduleRankingRuns, { ScheduleCheckedEnum } from '../ranking-run/schedule-ranking-run'
import { CustomGrid } from '../../custom-grid/custom-grid'
import useAgeGroupOptions, { GetAgeRestrictionLabelProps, GetDivisionTypeLabelProps } from '../utils/useAgeGroupOptions'
import getListType from '../utils/getListType'
import { RankingRunProps } from './ranking-runs'

interface RankingRunsHeaderProps {
  rankingRun: RankingRunProps;
  scheduleDisabled: boolean;
  setSchedule: (checked: boolean) => void;
  scheduleChecked: ScheduleCheckedEnum;
}

const RankingRunsHeader: React.FC<RankingRunsHeaderProps> = ({
  rankingRun,
  scheduleDisabled,
  setSchedule,
  scheduleChecked,
}) => {
  const { t } = useTranslation()
  const { isNational } = useOrgLevel()
  const { getAgeRestrictionLabel, getDivisionTypeLabel } = useAgeGroupOptions()
 
  const { 
    rankListName, 
    listType,
  } = rankingRun

  const displayScheduledRuns = useMemo(() => 
    ![ListTypeEnum.L2_QUALIFIER].includes(listType as ListTypeEnum), [listType]
  )

  const handleSetScheduleChecked = useCallback((checked: boolean) => {
    setSchedule(checked)
  }, [setSchedule])

  const ageRestrictionLabel = getAgeRestrictionLabel({ ...rankingRun, t } as GetAgeRestrictionLabelProps)
  const divisionTypeLabel = getDivisionTypeLabel({ ...rankingRun, t } as GetDivisionTypeLabelProps)

  return (
    <Panel extendedPadding>
      <Grid container item xs alignItems="center" justifyContent="space-between">
        <H2 spacing={{ margins: { sm: 'right' } }} noMargin>
          {rankListName}
        </H2>
        {isNational && displayScheduledRuns && (
          <ScheduleRankingRuns 
            checked={scheduleChecked === ScheduleCheckedEnum.ON} 
            setChecked={handleSetScheduleChecked} 
            disabled={scheduleDisabled} 
          />
        )}
      </Grid>
      <Divider className={styles.divider}/>
      <Grid container>
        <CustomGrid spacing={{ margins: { lg: 'right' } }}>
          <Body size="md" bold>
            {t('player category')}
          </Body>
          <Body size="md">{rankingRun.playerType}</Body>
        </CustomGrid>

        <CustomGrid spacing={{ margins: { lg: 'horizontal' } }}>
          <Body size="md" bold>
            {t('age group')}
          </Body>
          <Body size="md">{ageRestrictionLabel}</Body>
        </CustomGrid>

        <CustomGrid spacing={{ margins: { lg: 'horizontal' } }}>
          <Body size="md" bold>
            {t('division type')}
          </Body>
          <Body size="md">{divisionTypeLabel}</Body>
        </CustomGrid>

        <CustomGrid spacing={{ margins: { lg: 'horizontal' } }}>
          <Body size="md" bold>
            {t('list type')}
          </Body>
          <Body size="md">{getListType(rankingRun.listType as ListTypeEnum, t)}</Body>
        </CustomGrid>
      </Grid>
    </Panel>
  )
}

export default RankingRunsHeader