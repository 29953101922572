import React from 'react'
import * as styles from './schedule-ranking-run.module.less'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Icon from '../../icon/icon'
import { Body } from '@clubspark-react/clubspark-react-tools'
import { SliderComponent } from '../../slider/slider'
import classNames from 'classnames'

export enum ScheduleCheckedEnum {
  IDLE,
  OFF,
  ON
}

interface ScheduleRankingRunsProps {
  checked: boolean
  setChecked: (checked: boolean) => void
  disabled?: boolean
}

const ScheduleRankingRuns: React.FC<ScheduleRankingRunsProps> = ({ checked, setChecked, disabled }) => {
  const { t } = useTranslation()

  return (
    <Grid
      className={classNames(styles.container, {
        [styles.containerActive]: checked,
        [styles.disabled]: disabled
      })}
    >
      <Grid container alignItems="center">
        <Icon name="sm-clock" />
        <div className={styles.textAligner}>
          <Body size="md" bold spacing={{ margins: { xs: 'left' } }}>
            {t('scheduled runs')}
          </Body>
        </div>
        <SliderComponent
          checked={checked}
          onChange={() => setChecked(!checked)}
          disabled={disabled}
        />
        <span className={classNames(styles.sliderStatus, { [styles.sliderStatusActive]: checked })}>
          {checked ? t('on') : t('off')}
        </span>
      </Grid>
    </Grid>
  )
}

export default ScheduleRankingRuns
