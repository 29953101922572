import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { CustomGrid } from '../../custom-grid/custom-grid'
import { Body, H3 } from '../../typography/typography'
import DatePicker from '../../date-picker/date-picker'
import Button from '../../button/button'
import FormErrorMessage from '../../form-error-message/form-error-message'
import useDateRange from '../utils/useDateRange'
import { getOneDayBefore, getOneYearAgo } from 'src/utils/helper/dates'

interface NewManualRankingRunDialogProps {
  title: string
  onCancel: () => void
  onSubmit: (formData: any) => void
  loading: boolean
  error?: string
}

const NewManualRankingRunDialog: React.FC<NewManualRankingRunDialogProps> = ({ 
  title, 
  onCancel, 
  onSubmit, 
  loading, 
  error 
}) => {
  const { t } = useTranslation()
  const { handleSubmit, control, setValue } = useForm()

  const handleCreateNewRankList = useCallback((formData) => {
    onSubmit(formData)
  },[onSubmit])

  // By default, set the start and end date to construct a list across the last 12 months 
  useDateRange(
    setValue,
    getOneYearAgo,
    getOneDayBefore
  )

  return (
    <form onSubmit={handleSubmit(handleCreateNewRankList)} data-testid='new-ranking-run-form'>
      <CustomGrid container direction='row' spacing={{ margins: { lg: 'bottom' } }}>
        <H3>{title}</H3>
        <CustomGrid item xs spacing={{ margins: { sm: 'right' } }}>
          <Body size="md" spacing={{ margins: { xs: 'vertical' } }}>
            {t('run period start')}
          </Body>
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                selected={field.value}
                onChange={(date) => field.onChange(date)}
              />
            )}
          />
        </CustomGrid>
        <CustomGrid item xs spacing={{ margins: { sm: 'left' } }}>
          <Body size="md" spacing={{ margins: { xs: 'vertical' } }}>
            {t('run period end')}
          </Body>
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                selected={field.value}
                onChange={(date) => field.onChange(date)}
              />
            )}
          />
        </CustomGrid>
      </CustomGrid>
      <CustomGrid
        container
        justify="flex-end"
        spacing={{ margins: { lg: 'top' } }}
        style={{ marginTop: 150 }}
      >
        <Button onClick={onCancel} type="button" level="tertiary">
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          spacing={{ margins: { sm: 'left' } }}
          loading={loading}
        >
          {t('save run')}
        </Button>
      </CustomGrid>
      <CustomGrid style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {error && (
          <FormErrorMessage 
            spacing={{ margins: { md: 'top' } }}
            message={t('generate ranklist error')}
          />
        )}
      </CustomGrid>
    </form>
  )
}

export default NewManualRankingRunDialog